.formContain{
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: linear-gradient(90deg, rgb(62 62 62) 0%, rgb(0 0 0) 200%) !important;
    
}
.InputContain{
    height: 100vh;
    width: 50%;
    background-image: url('../images/backtest.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.PhotoContain{
    height: 100vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.PhotoContainInner{
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 15%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid lightgray;
    width: 300px;
}
.wrongCreds{
    color: var(--mainColor)
}
.credentialsStyle{
    padding: 10px;
    border: none;
    border-radius: 30px;
    padding-left: 15px;
    width: calc(100% - 25px);
    padding: 15px 20px;
}

.credentialsStyle::placeholder{
    color: #9b9a9a;
    font-weight: 500;
}

.credentialsStyle:focus-visible{
    outline: none;
}
.credentialsStyle:focus{
    box-shadow: inset 0px 0px 20px 0px rgb(0 0 0 / 30%);
    border: 1px solid #fff;
}
.submitPossition{
    
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 100%;
    align-items: right;
}
.submitStyle{
    padding: 10px 15px;
    background: linear-gradient(90deg, rgba(227,18,13,1) 0%, rgba(32,33,36,1) 200%);
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
}

.submitStyle:hover{
    transition: .7s;
    color:#fff;
    transform: scale(1.1);
}