.TopBarContainer{
    background: linear-gradient(90deg, rgba(227,18,13,1) 0%, rgba(32,33,36,1) 200%);
    height: 80px;
    display: flex;
}
.TopBarContainerStick{
    background: linear-gradient(90deg, rgba(227,18,13,1) 0%, rgba(32,33,36,1) 200%);
    height: 0px;
    padding-top: 50px;
    display: flex;
    position: fixed;
    width: 100%;
    /* margin-left: -10px; */
    animation-duration: .5s;
    animation-name: fadedown;
    z-index: 1;
}
@keyframes fadedown {
    0% { 
      /* CSS properties */
      transform: translateY(-80px);
    }
    100% {
        /* CSS properties */
        transform: translateY(0px);
        transition: 5s all ease;
    }
   }
.TopBarContainerInner{
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}
.logo{
    width: auto;
    height: 70px;
}
.logoutText{
    color: var(--whiteFont);
    font-size: var(--mediumText);
}
.WeclomeContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.welcomeText{
    color: var(--whiteFont);
    font-size: var(--xlargeText);
    font-weight: var(--fontBold);
}
.datetimeText{
    color: var(--whiteFont);
    font-size: var(--smallText);
}