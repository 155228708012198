.SideBarContainer{
    
}

.SideBarContainerInner{
    box-shadow: var(--boxShadow);
    border-radius: 8px;
    position: fixed;
    top: 120px;
    width: 20%;
}

.SideBarContainerInnerSticky{
    box-shadow: var(--boxShadow);
    border-radius: 8px;
    position: fixed;
    top: 150px;
}

.SideNavDiv{
    padding:30px 20px;
    border-bottom: 1px solid #ccc;
}
.SideNavDiv:hover{
    border-left: 3px solid var(--mainColor);
}
.SideNavDiv:hover a{
    color: var(--mainColor);
}
.Last{
    border-bottom: 0px;
}
.SideNavDiv a{
    color: var(--fontBlack);
    font-size: var(--normalText);
    transition: .5s;
}

.SideNavDiv a:hover{
    color: var(--mainColor);
    transition: .5s;
}

.SideNavDivFilterComps {
    margin:0px 0px 10px 30px;
    line-height: 25px;

}

.SideNavDivFilterCompsInner:hover{
    border-left: 2px solid var(--mainColor);
    padding-left: 3px;  
}
.SideNavDivFilterCompsInner:hover a{
    transition: 1s;
    color:var(--mainColor);

}
.SideNavDivFilterCompsNone{
    display: none;
    color: var(--mainColor);
    transition: 1s;

}

