@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600&display=swap');
*{margin:0;padding:0;font-family: 'Manrope', sans-serif;}
a{
  text-decoration: none;
  color:inherit;
}
span{
  color:inherit;
}
:root{
  --smallText:12px;
  --mediumText:14px;
  --normalText: 16px;
  --largeText: 18px;
  --xlargeText: 20px;
  --headingText:20px;
  --whiteFont: #fff;
  --fontBlack: #202124;
  --fontBold: 600;
  --mainColor:#E2120B;
  --boxShadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
}

.mainPanel{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  margin: 0 auto;
  padding-top: 40px;
}

.PanelContainer{
  width: 70%;
  box-shadow: var(--boxShadow);
  border-radius: 8px;
  padding: 30px 30px;
  margin-bottom: 60px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f6f6f6; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #E2120B;  */
  background: linear-gradient(90deg, rgba(227,18,13,1) 0%, rgba(32,33,36,1) 200%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #990d08; 
}

/* EDITOR STYLE */
.dx-htmleditor-content{
  height: 350px !important;
}

/* FILE UPLOADER */
.dx-fileuploader-input-wrapper {
  padding-left: 0px;
  border:none;
}

.dx-fileuploader-wrapper {
  padding-left: 0px;
}

/* ARTICLE WRITE BOX*/

.dx-htmleditor.dx-htmleditor-outlined.dx-state-focused{
  border-color: var(--fontBlack);
}

.dx-calendar-navigator-next-view.dx-button .dx-icon, .dx-calendar-navigator-previous-view.dx-button .dx-icon{
  color:var(--fontBlack);
}

.dx-calendar-cell.dx-calendar-selected-date, .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today {
  color: #fff;
  -webkit-box-shadow: inset 0 0 0 1000px var(--mainColor);
  box-shadow: inset 0 0 0 1000px var(--mainColor);

}
.dx-calendar-cell.dx-calendar-selected-date, .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-date{
  color: #fff;
  -webkit-box-shadow: inset 0 0 0 1000px var(--mainColor);
  box-shadow: inset 0 0 0 1000px var(--mainColor);
}
.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today.dx-calendar-contoured-date{
  color: #fff;
  font-weight: bold;
  -webkit-box-shadow: inset 0 0 0 1000px var(--mainColor);
  box-shadow: inset 0 0 0 1000px var(--mainColor);
}
.dx-calendar-navigator .dx-calendar-caption-button.dx-button .dx-button-content {
  color: var(--fontBlack);
}
.dx-state-focused.dx-htmleditor.dx-htmleditor-outlined{
  border:1px solid lightgray;
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  padding: 5px;
  width: max-content !important;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: max-content !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}