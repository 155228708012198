.footerContainer{
    background: var(--fontBlack);
    color: #fff;
    text-align: center;
    padding: 3px;
    bottom: 0;
    position: fixed;
    width: 100%;
}
.footerContainer a{
    color: #fff;
    font-size: var(--smallText);
}