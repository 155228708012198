/* .articlesContainer{
    width: 100vw;
    margin-top: 100px;
    margin-bottom: 100px;
} */

.articlesContainerInner{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    margin-bottom: 10px;
    border-bottom: 1px solid #ebebeb;
    padding:15px;
}

.articlesContainerInner:hover{
    /* box-shadow: var(--boxShadow); */
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,.3);
    transition: .5s;
    border-radius: 5px;
}

.articlesMainImg{
    height: 160px;
    width: auto;
    background-position: center;
    background-size: cover;
}

.articlesDivImg{
    width: 30%;
}

.articlesTitle{
    color: var(--fontBlack);
    font-weight: var(--fontBold);
    font-size: var(--largeText);
}

.articlesBody{
    color: var(--fontBlack);
    font-size: var(--mediumText);
}

.articlesDate{
    color: var(--fontBlack);
    font-size: var(--smallText);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
}
.articlesDate img{
    width: 20px;
    margin-right: 3px;
}
.articlesAuthor{
    background-color:rgba(227,18,13,1);
    color:white;
    width: fit-content;
    font-weight: bold;
    font-size: 12px;
    border-radius: 2px;
    padding: 3px 10px 3px 10px;
}
.articlesDetailsDiv{
    padding:0px 15px;
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
}

.editdelete{
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 15px;
    color:var(--fontBlack);
    font-size: var(--mediumText);
    cursor: pointer;
}
.editbtn:hover{
    transform: scale(1.1);
    transition: .2s;
}
.deletebtn:hover{
    transform: scale(1.1);
    transition: .2s;
}

.editdelete div{
    display: flex;
    align-items: center;
}
.editdelete img{
    width: 18px;
    height: auto;
    padding-right: 5px;
}
.bottomContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
.confirmContainer{
    display: flex;
    width: 30%;
    align-self: flex-end;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 10px;
    padding: 20px 20px;
    /* cursor: pointer; */
}
.confirmContainer:hover{
    cursor: pointer;
}
.confirmContainerHidden{
    display: none;
}
.confirmBtn{
    padding: 0px 5px;
}
.confirmBtn:hover{

    border-bottom: 1px solid var(--mainColor);
    transform: scale(1.1);
    transition: .2s;
}